import { ErrorHandlerInterceptor } from './shared/interceptors/error-handler.interceptor';
import { SpinnerModule } from "./spinner/spinner.module";
import { BrowserModule } from "@angular/platform-browser";
import { Injector, NgModule, LOCALE_ID } from "@angular/core";
import { HeadersInterceptor } from './shared/interceptors/headers.interceptor';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { PushNotificationService } from "./shared/services/push-notification.service";
import { SpinnerInterceptor } from "./spinner/interceptors/spinner.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { PushNotificationModule } from 'ng-push-notification';
import localePT from '@angular/common/locales/pt';
// import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePT);
// registerLocaleData(localeES);

// import { TwitterService } from 'ngx-twitter-api';
export let InjectorInstance: Injector;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    SpinnerModule,
    BrowserAnimationsModule,
    PushNotificationModule.forRoot(/* Default settings here, interface PushNotificationSettings */),
  ],
  providers: [
    //TwitterService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "235503570217-jpdmbor9fcdufvae8ukiimpdvec4pbvk.apps.googleusercontent.com" // codigo valido em dev
              // "909206300603-f0ps9qbkraiu824ps5alhvn54qosvgco.apps.googleusercontent.com" // codigo valido em prod
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("202716845069727"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      useExisting: undefined,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true  },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'pt-br' },
    PushNotificationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
