import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SettingsServiceService {
  public setting: any;
  constructor() {
    this.readConfig();
  }

  public readConfig() {
    fetch("/settings.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("No se pudo cargar el archivo JSON.");
        }
        return response.json();
      })
      .then((data) => {
        this.setting = data;
      });
  }
}
