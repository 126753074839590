import { StorageService } from "src/app/shared/services/storage.service";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { /*catchError,*/ Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SpinnerService } from "src/app/spinner/spinner.service";
// import { NgxSpinnerService } from 'ngx-spinner'

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  public appIdentifier = environment.identifier;
  public code = environment.code;
  public defaultLanguage = environment.defaultLanguage;
  constructor(
    private serviceAuth: StorageService,
    private spinnerService: SpinnerService
    // private spinner: NgxSpinnerService
  )
  {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const httpReq = req.clone({
      // setHeaders: {'Content-Type': 'application/json'}
      // url: req.url.replace('http://', 'https://'),
      url: req.url.replace("http://", "http://"),
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Content-Type": "application/json",
        "Accept-Language": this.getLanguage(),
        "Accept-Code": this.code,
        Authorization: `Bearer ${this.serviceAuth.getToken()}`,
      }),
    });
    // this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      // this.spinner.hide();
    }, 5000);
    // console.log(this.appIdentifier);
    return next.handle(httpReq)
    // .pipe(
    //   tap(
    //     (event: HttpEvent<any>) => {
    //       if (event instanceof HttpResponse) {
    //         // Manejar la respuesta
    //       }
    //     },
    //     (error: HttpErrorResponse) => {
    //       console.log(error.status)
    //       if (error.status == 401) {
    //         //
    //       }
    //     }
    //   )
    // );
  }

  private getLanguage() {
    if (localStorage.getItem(`user_information_${this.appIdentifier}`)) {
      const currentUser = JSON.parse(
        localStorage.getItem(`user_information_${this.appIdentifier}`)
      );
      if (currentUser && currentUser.profile && currentUser.profile.locale) {
        return currentUser.profile.locale;
      }
    }
    return this.defaultLanguage;
  }
}
