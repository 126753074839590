import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class StorageService {
  public identifier = environment.identifier;
  public change: EventEmitter<boolean> = new EventEmitter();
  constructor(private router: Router) {}

  getToken(): string {
    return localStorage.getItem("user_" + this.identifier)
      ? localStorage.getItem("user_" + this.identifier)
      : "";
  }

  public getProfile(): any[] {
    return JSON.parse(
      localStorage.getItem("user_information_" + this.identifier)
    );
  }

  getFcmToken(): string {
    if (!localStorage.getItem("fcm_" + this.identifier)) {
      return null;
    }

    return localStorage.getItem("fcm_" + this.identifier);
  }

  clear(): void {
    localStorage.removeItem("user_information_" + this.identifier);
    localStorage.removeItem("user_" + this.identifier);
    localStorage.removeItem("fcm_" + this.identifier);
    localStorage.removeItem("acceptPolicies_" + this.identifier);
  }

  updatePreviousUrl() {
    let href = this.router.url;
    href = href.substring(1);
    sessionStorage.setItem("previousUrl", href);
  }

  getPreviousUrl() {
    return sessionStorage.getItem("previousUrl");
  }

  logOut(): void {
    /*localStorage.removeItem("user_information_" + this.identifier);
    localStorage.removeItem("user_" + this.identifier);*/
    this.clear();
    // this.router.navigate(["logout"]);
  }

  emitChangeEvent(bool: boolean): void {
    this.change.emit(bool);
  }

  getChangeEmitter() {
    return this.change;
  }
}
