import { ActivateAccountModule } from "./activate-account/activate-account.module";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "atualizar-cadastro",
    loadChildren: () =>
      import("./sign-up/sign-up.module").then((m) => m.SignUpModule),
  },
  {
    path: "crear-conta",
    loadChildren: () =>
      import("./create-account/create-account.module").then(
        (m) => m.CreateAccountModule
      ),
  },
  {
    path: "ativar-conta",
    loadChildren: () =>
      import("./activate-account/activate-account.module").then(
        (m) => m.ActivateAccountModule
      ),
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./logout/logout.module").then((m) => m.LogoutModule),
  },
  {
    path: "mapa-do-sitio",
    loadChildren: () =>
      import("./site-map/site-map.module").then((m) => m.SiteMapModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  { path: "", redirectTo: "", pathMatch: "full" },
  /*{ path: '**', component: NotFoundComponent },*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
