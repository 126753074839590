import { SpinnerService } from './../spinner.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor{
  constructor(private service: SpinnerService){

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.service.show();
    return next.handle(req).pipe(finalize(() => this.service.hide()));
  }

}
